.labelText {
  color: var(--color-neutral-600);
  display: inline-block;
  font-weight: 600;
  text-align: left;
  text-transform: var(--label-text-transform);

  &.large {
    font-size: var(--text-m);
    font-weight: var(--medium);
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.label {
  align-items: center;
  display: inline-flex;
}

.label + div {
  margin-top: var(--gap-small);
}

.helpInfo {
  line-height: 1;
  margin-left: var(--gap-small);
}

.fullWidth {
  width: 100%;
}

.optional {
  font-weight: 400;
}
