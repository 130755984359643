.field {
  --padding-inline: 25px;
  --padding-block: 15px;
  -webkit-appearance: none;

  appearance: none;
  background: var(--color-neutral-0);
  border: var(--border-width) solid var(--color-neutral-40);
  border-radius: var(--container-border-radius);
  box-shadow: none;
  color: var(--color-text-primary);
  font: inherit;
  margin: 0;
  min-height: var(--field-min-height);
  outline: none;
  padding: var(--padding-block) var(--padding-inline);

  &.errorField:not(:disabled) {
    background-color: var(--color-error-light);
    border-color: var(--color-error);
  }

  &:not(:disabled):focus,
  &:not(:disabled):hover {
    border-color: var(--color-primary-300);
  }

  &:disabled {
    background: var(--color-neutral-10);
    color: var(--color-neutral-500); /* primary text does not have sufficient WCAG AA contrast */
  }

  &::placeholder {
    color: var(--color-neutral-70);
    font-weight: var(--light);
  }
}

.container {
  --border-width: 1px;
  --border-radius: var(--container-border-radius);
  display: inline-block;
  text-align: left;
}

.fullWidth {
  width: 100%;
}

.label {
  display: inline-block;
  user-select: none;

  &[for] {
    cursor: pointer;
  }
}

.description {
  color: var(--color-neutral-90);
  display: block;
  margin-bottom: var(--gap-small);
}

.labelText {
  composes: labelText from '../label/label.module.css';
  display: block;
  margin-bottom: var(--gap-small);
}

.fieldErrorMessage {
  display: block;
  margin-top: 0.1rem;
  text-align: left;
}

.uppercaseLabel {
  --label-text-transform: uppercase;
}

.hiddenLabel {
  @media (--tablet) {
    visibility: hidden;
  }
}

.phoneInput {
  padding-left: 50px;
}
