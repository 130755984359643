.button {
  --size: 2rem;

  align-items: center;
  appearance: none;
  background: transparent none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease;

  > svg {
    flex-shrink: 0;
    height: 1.2rem;
    width: 1.2rem;
  }
}

.afterText {
  margin-left: 13px;
}

.beforeText {
  margin-right: 13px;
}
