.flex {
  display: flex;

  & > * {
    margin-bottom: 0;
  }
}

.inlineFlex {
  display: inline-flex;
}

.gap {
  gap: var(--gap);
}

.gapSmall {
  gap: var(--gap-small);
}

.gapTiny {
  gap: var(--gap-tiny);
}

.flexAlignItemsStart {
  align-items: start;
}

.flexAlignItemsCenter {
  align-items: center;
}

.flexAlignItemsEnd {
  align-items: end;
}

.flexDirectionColumn {
  flex-direction: column;
}

.flexJustifyContentSpaceBetween {
  justify-content: space-between;
}

.flexJustifyContentStart {
  justify-content: start;
}
