.icon {
  color: var(--color-danger);
  flex-shrink: 0;
  height: rem(32px);
  margin-right: var(--gap-small);
  width: rem(32px);
}

.content {
  align-items: flex-start;
  color: var(--color-neutral-200);
  display: flex;
}
