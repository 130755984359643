.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.partnerMain,
.main {
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.main {
  position: relative;
}

.partnerFooter {
  background-color: var(--color-primary-500);
}

.partnerMain {
  display: flex;
  flex-direction: column;
}

.partnerBackMobileLink {
  margin-bottom: var(--gap-huge);

  @media (--tablet) {
    display: none;
  }
}
