.root {
  --slider-height: 30px;
  align-items: center;
  border-radius: 99px;
  display: flex;
  height: var(--slider-height);
  margin-bottom: 55px;
  position: relative;
  touch-action: none;
  user-select: none;
  width: 100%;
}

.track {
  background: var(--slider-track-bg, var(--background-color-primary, #fff));
  border-radius: inherit;
  box-shadow: inset 0 8px 13px -5px rgba(110, 168, 186, 0.22);
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.range {
  --line-first-bg-color: red;
  --line-second-bg-color: green;
  background: red;
  background: repeating-linear-gradient(
    -45deg,
    var(--color-primary-75),
    var(--color-primary-75) 2px,
    var(--color-primary-100) 2px,
    var(--color-primary-100) calc(2px * 3)
  );
  border: 1px solid var(--color-primary-100);
  height: 100%;
  position: absolute;
}

.thumb {
  --triangle-height: 5px;
  --info-element-gap: 8px;

  all: unset;
  background-color: var(--color-primary-400);
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
  display: block;
  height: var(--slider-height);
  position: relative;
  transition: var(--transition-duration) all ease-in-out;
  width: var(--slider-height);

  &:hover {
    background-color: var(--color-primary-300);
    transition: var(--transition-duration) all ease-in-out;
  }

  &::after {
    background-color: var(--color-secondary-300);
    border-radius: inherit;
    content: '';
    display: block;
    height: 11px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: var(--transition-duration) all ease;
    width: 11px;
  }

  &::before {
    background-image: url('./slider-triangle.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: calc(var(--info-element-gap) * -1);
    content: '';
    height: var(--triangle-height);
    left: 50%;
    position: absolute;
    transform: translate(-50%, 100%);
    width: 7px;
  }
}

.tooltip {
  background-color: var(--background-color-primary);
  border-radius: 7px;
  bottom: calc(var(--info-element-gap) * -2 - var(--triangle-height));
  box-shadow: 0 4px 24px -5px rgba(0, 0, 0, 0.25);
  font-size: var(--text-xs);
  font-weight: bold;
  left: 50%;
  min-height: 26px;
  min-width: 80px;
  padding: 3px;
  position: absolute;
  text-align: center;
  transform: translate(-50%, 100%);
}
