.navContainer {
  border: var(--user-info-border);
  border-radius: 0 0 var(--user-info-border-radius) var(--user-info-border-radius);
  border-top: 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 100%;
  transition: all var(--transition-duration) ease-in-out;
  width: 100%;
  z-index: 1;
}

.navList {
  background-color: var(--background-color-primary);
  list-style: none;
  margin: 0;
  padding: 0 var(--user-info-horisonal-padding);
  text-align: right;
}

.navListItem {
  &:first-of-type {
    .link {
      padding-top: 10px;
    }
  }

  &:not(:last-of-type) {
    border-bottom: var(--user-info-border);
  }
}

.link {
  align-items: center;
  display: grid;
  grid-gap: var(--gap-tiny);
  grid-template-areas: 'icon label' 'desc desc';
  grid-template-columns: auto 1fr;
  outline: none;
  padding: 20px 0;
  text-align: left;
  text-decoration: none;

  &:focus-visible,
  &:hover,
  &.active {
    .label {
      color: var(--color-accent-primary);
      text-decoration: underline;
    }
  }
}

.icon {
  color: var(--color-primary-300);
  height: 1rem;
  width: 1rem;
}

.label {
  color: var(--color-primary-400);
  font-size: var(--text-s);
  font-weight: 600;
  grid-area: label;
}

.desc {
  color: var(--color-neutral-200);
  font-size: var(--text-xxs);
  grid-area: desc;
}
