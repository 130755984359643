.desc {
  margin-bottom: 54px;

  @media (--tablet-) {
    margin-bottom: var(--gap);
  }
}

.fields {
  display: grid;
  grid-gap: var(--gap-large) var(--gap-huge);

  @media (--tablet) {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
  }
}

.checkboxLabel {
  align-items: flex-start;
  color: var(--color-neutral-200);
  font-size: inherit;
  margin-top: var(--gap-large);
}

.btn {
  @media (--tablet) {
    margin-right: var(--gap-small);
  }

  @media (--phone) {
    width: 100%;
  }
}

.submit {
  margin-bottom: var(--gap-small);
}

.marketSelectLabel {
  --label-text-transform: inherit;
}
