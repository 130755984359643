.container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  word-break: normal;
}

.loader {
  --loader-color: var(--color-accent-primary);
}

.button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(3, 1fr);
  padding: 5px;
  position: relative;
}

.dot {
  background-color: var(--color-primary-300);
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  height: rem(4px);
  margin: auto;
  width: rem(4px);
}

.menu {
  background-color: var(--background-color-primary);
  border: 1px solid var(--color-primary-300);
  border-radius: var(--container-border-radius);
  bottom: 0;
  color: var(--color-neutral-900);
  font-size: var(--text-xxs);
  font-weight: 400;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  list-style: none;
  margin: 0;
  padding: 8px 0;
  position: absolute;
  right: 0;
  transform: translateY(calc(100% + 5px));
  z-index: 1;
}

.menuItem {
  cursor: pointer;
  padding: 0 26px;
  text-wrap: nowrap;

  &:hover {
    background-color: var(--color-primary-50);
  }
}
