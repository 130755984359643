.list {
  font-size: var(--text-s);
  list-style: none;
  margin: 0;
  margin-top: var(--gap-tiny);
  padding: 0;
}

.listItem {
  margin-bottom: var(--gap-small);
}
