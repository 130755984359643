.button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;

  color: var(--color-primary-100);
  display: flex;
  height: 100%;
  justify-content: flex-start;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;

  &:focus-visible,
  &:hover {
    color: var(--color-primary-400);
  }
}

.icon {
  cursor: pointer;
  height: 23px;
  object-fit: contain;
  object-position: center;
  width: 26px;
}

.inputWrapper {
  position: relative;
}

.input {
  padding-right: 50px;
}
