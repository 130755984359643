.headerContent {
  align-items: end;
  box-sizing: border-box;
  display: grid;
  grid-gap: var(--gap) var(--gap-small);
  grid-template-areas:
    'logo . homesellers buyNow'
    'title nav nav nav';
  grid-template-columns: auto 2fr 1fr 1.1fr;
  justify-content: space-between;
  left: 0;
  position: fixed;
  right: 0;
  top: -1px;
  transition: 0.2s all ease;

  @media (--tablet-) {
    --tablet-nav-margin-top: calc(rem(29px) - var(--gap-small));
    align-items: end;
    grid-gap: var(--gap-small);
    grid-template-areas:
      'logo  homesellers'
      'title buyNow'
      'nav nav';
    grid-template-columns: auto auto;
    padding-bottom: var(--gap);
    padding-top: var(--gap);
  }

  @media (--phone) {
    grid-gap: var(--gap-small) var(--gap-tiny);
    grid-template-areas: 'logo burger' 'title burger';
    grid-template-columns: 1fr auto;
    padding-bottom: var(--gap-small);
    padding-top: var(--gap-small);
    text-align: left;

    &::after {
      background-color: var(--background-color-primary);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  &.scrolled {
    @media (--tablet-only) {
      --tablet-nav-margin-top: var(--gap-tiny);
      padding-bottom: var(--gap-small);
      padding-top: var(--gap-small);
    }
    @media (--desktop) {
      grid-gap: var(--gap-small);
      height: auto;
      padding-bottom: var(--gap);
      padding-top: var(--gap);
    }
  }

  &.static {
    position: relative;
    visibility: hidden;
    z-index: -1;
  }
}

.logoWrapper {
  align-items: center;
  align-self: center;
  display: flex;
  grid-area: logo;
  justify-content: flex-start;
  justify-self: start;
  position: relative;
  text-decoration: none;
  z-index: 1;

  span {
    color: var(--color-neutral-500);
    font-weight: 400;
    margin-right: 11px;
  }
}

.logoContainer {
  height: 36px;
  width: 286px;
  @media (--tablet-) {
    height: 20px;
    width: 186px;
  }

  @media (--phone) {
    height: 20px;
    width: 158px;
  }
}

.logo {
  &:focus-visible {
    border: none;
    outline: none;
  }
}

.burger {
  grid-area: burger;
  position: relative;
  z-index: 1;
  @media (--tablet) {
    display: none;
  }
}

.title {
  align-self: center;
  color: var(--color-neutral-100);
  font-size: 30px; /*fallback*/
  font-size: min(2.45vw, 30px);
  font-weight: 700;
  grid-area: title;
  justify-self: start;
  letter-spacing: -0.02em;
  line-height: 1em;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;

  @media (--tablet-) {
    font-size: rem(26px);
  }

  @media (--phone) {
    font-size: rem(23px);
    font-weight: 600;
  }
}

.nav {
  grid-area: nav;
  justify-self: end;
}

.buyNow {
  grid-area: buyNow;
  @media (--phone) {
    display: none;
  }
}

.homesellers {
  grid-area: homesellers;
  min-width: 185px;
  @media (--phone) {
    display: none;
  }
}

.dialogBody {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
