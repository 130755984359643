.container {
  align-items: center;
}

.icon {
  margin-left: var(--gap-small);
}

.additionalInfo {
  display: block;
  font-size: var(--text-xs);
  font-weight: normal;
  text-transform: initial;

  &:not(:last-child):not(:first-child) {
    margin-bottom: var(--gap-small);
  }
}
