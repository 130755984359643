/* stylelint-disable declaration-no-important */
.tooltip {
  border: 1px solid var(--color-primary-75) !important;
  border-radius: 5px !important;
  color: var(--color-primary-400) !important;
  font-weight: 400;
  max-width: 245px;
  opacity: 1 !important;
  padding: 15px !important;
  position: relative;
  text-transform: initial;

  &:global {
    &::before {
      border-top-color: var(--color-primary-75) !important;
    }
  }
}
/* stylelint-enable declaration-no-important */
