.icon {
  align-items: center;
  color: var(--color-primary-300);
  cursor: help;
  display: inline-flex;
  flex-shrink: 0;
  height: 0.8rem;
  justify-items: center;
  width: 0.8rem;
}
