.status {
  align-items: center;
  display: flex;

  &::before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: rem(12px);
    margin-right: var(--gap-small);
    width: rem(12px);

    &.paid {
      background-color: #68c87d;
    }

    &.pending {
      background-color: #ffc164;
    }

    &.processing {
      background-color: var(--color-primary-300);
    }

    &.cancelled {
      background-color: #ff8064;
    }
  }
}
