.avatar {
  align-items: center;
  aspect-ratio: 3/3;
  background-color: color-mod(var(--color-neutral-30) alpha(30%));
  border: 1px solid var(--color-neutral-30);
  border-radius: var(--container-border-radius);
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 110px;

  &.rounded {
    border-radius: 50%;
    padding: 3px;
  }
}

.image {
  object-fit: contain;
  object-position: center;
}

.defaultAvatar {
  height: 70%;
  margin: auto;
  width: 70%;
}

.clickableAvatar {
  cursor: pointer;
}
