.loader {
  --size: 30px;
  align-items: center;
  bottom: 20px;
  cursor: wait;
  display: flex;
  height: var(--size);
  justify-content: center;
  position: fixed;
  right: 20px;
  width: var(--size);
  z-index: var(--z-index-modal);

  span {
    animation: ripple 1.6s ease-out infinite;
    border: 1px solid var(--color-accent-primary);
    border-radius: 50%;
    position: absolute;
  }

  span:nth-child(2) {
    animation-delay: -0.8s;
  }
}

@keyframes ripple {
  from {
    height: 0;
    opacity: 0;
    width: 0;
  }
  to {
    height: 100%;
    opacity: 1;
    width: 100%;
  }
}
