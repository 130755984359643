.alert {
  --alert-padding-x: var(--gap);
  --alert-padding-y: rem(20px);
  --alert-border-color: transparent;
  --alert-border: 1px solid var(--alert-border-color);
  --alert-color: inherit;
  --alert-bg: transparent;
  --alert-border-radius: var(--container-border-radius);
  --alert-title-color: var(--alert-border-color);
  --alert-icon-width: rem(41px);
  --alert-icon-height: var(--alert-icon-width);
  --icon-gap: var(--gap-small);
  background: var(--alert-bg);
  border: var(--alert-border);
  border-radius: var(--alert-border-radius);
  color: var(--alert-color);
  display: grid;

  padding: var(--alert-padding-y) var(--alert-padding-x);
  position: relative;
  text-align: left;

  @media (--phone) {
    --alert-padding-y: rem(17px);
  }

  @media (--tablet) {
    font-size: var(--text-m);
  }
}

.withIcon {
  padding-left: calc(var(--alert-icon-width) + var(--alert-padding-x) + var(--icon-gap));
}

.fullWidth {
  width: 100%;
}

.content {
  & > *:last-child {
    margin-bottom: 0;
  }
}

.icon {
  bottom: 0;
  color: var(--alert-border-color);
  display: block;
  height: var(--alert-icon-height);
  left: var(--alert-padding-x);
  margin-bottom: auto;
  margin-top: auto;
  position: absolute;
  top: 0;
  width: var(--alert-icon-width);
}

.infoTheme {
  --alert-border-color: var(--color-primary-300);
  --alert-color: var(--color-neutral-500);
  --alert-bg: var(--color-primary-50);
}

.errorTheme {
  --alert-border-color: var(--color-error);
  --alert-bg: var(--color-error-light);
  --alert-color: var(--color-neutral-800);
}

.warningTheme {
  --alert-border-color: var(--color-secondary-400);
  --alert-bg: var(--color-secondary-50);
}

.alert p:only-child {
  margin-bottom: 0;
}
