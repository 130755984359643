.button {
  --btn-min-height: 46px;
  align-items: center;
  appearance: none;
  background-color: var(--bg-color);
  border: none;
  border-radius: 3rem;
  color: var(--color);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family);
  font-size: var(--text-s);
  font-weight: 500;
  justify-content: center;
  letter-spacing: 1px;
  min-height: var(--btn-min-height);
  min-width: 145px;
  outline: none;
  padding: var(--gap-tiny) var(--gap);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:focus-visible,
  &:hover {
    background-color: var(--hover-bg-color, var(--bg-color));
    color: var(--hover-color, var(--color));
  }

  &:disabled {
    background-color: var(--color-neutral-70);
    cursor: not-allowed;
  }
}

.clearStyle {
  all: unset;
  background-color: var(--bg-color);
  cursor: pointer;
}

.fullWidth {
  width: 100%;
}

.primary,
.primarySmall {
  --color: var(--color-neutral-0);
  --bg-color: var(--color-primary-400);
  --hover-bg-color: color-mod(var(--color-primary-400) shade(20%));
  font-size: var(--text-s);
}

.primaryLight,
.primarySmallLight {
  --color: var(--color-neutral-0);
  --bg-color: var(--color-primary-400);
  --hover-bg-color: color-mod(var(--color-primary-400) shade(5%));
}

.primaryDanger {
  --color: var(--color-neutral-0);
  --bg-color: var(--color-danger);
  --hover-bg-color: color-mod(var(--color-danger) shade(20%));
}

.primarySmall,
.primarySmallLight,
.secondarySmall {
  font-size: var(--text-xs);
  min-height: auto;
  min-width: auto;
  padding: var(--gap-tiny) var(--gap-small);
}

.secondary,
.secondarySmall,
.secondaryToMobileBtn {
  --color: var(--color-primary-400);
  --bg-color: transparent;
  --hover-color: var(--color-primary-500);
  --hover-bg-color: color-mod(var(--color-neutral-0) shade(5%));

  box-shadow: inset 0 0 0 1px currentColor;
}

.secondaryToMobileBtn {
  @media (--phone) {
    --color: var(--color-primary-500);
    box-shadow: none;
  }
}

@keyframes __bounceInRight {
  0%,
  100% {
    left: var(--gap);
  }
  50% {
    left: calc(var(--gap) / 2);
  }
}

@keyframes __bounceInLeft {
  0%,
  100% {
    left: calc(-1 * var(--gap));
  }
  50% {
    left: calc((var(--gap) / 2) * -1);
  }
}

.goBack,
.accentLinkToMobileBtn,
.accentLink {
  --color: var(--color-primary-400);

  font-weight: 600;
  min-height: auto;
  min-width: auto;
  padding-left: 0;
  padding-right: rem(25px);
  position: relative;

  &::before,
  &::after {
    background-image: url('../../icons/long-arrow-right.svg');
    background-repeat: no-repeat;
    content: '';
    height: 16px;
    left: var(--gap);
    position: relative;
    transition: var(--transition-duration) ease-in-out;
    width: 19px;
  }

  &::before {
    background-image: url('~/icons/long-arrow-left.svg');
    display: none;
  }

  &:focus::before,
  &:hover::before {
    left: calc(var(--gap-small) * -1);
    transition: var(--transition-duration) ease-in-out;
  }

  &:focus::after,
  &:hover::after {
    left: var(--gap-small);
    transition: var(--transition-duration) ease-in-out;
  }
}

.goBack {
  padding-left: 25px;
  padding-right: 0;

  &::after {
    display: none;
  }

  &::before {
    display: block;
    left: calc(-1 * var(--gap));
  }
}

.accentLinkToMobileBtn {
  @media (--phone) {
    --hover-bg-color: color-mod(var(--color-neutral-0) shade(5%));
    box-shadow: inset 0 0 0 1px var(--color-neutral-90);
    color: var(--color-accent-primary);
    margin-top: var(--gap-large);
    min-height: var(--btn-min-height);
    padding: var(--gap-tiny) var(--gap);
    width: 100%;

    &:hover {
      color: var(--color-accent-primary);
    }

    &::after {
      animation: none;
      display: none;
    }
  }
}

.text {
  --color: var(--color-neutral-90);
  --bg-color: transparent;
  --hover-bg-color: color-mod(var(--color-neutral-0) shade(5%));
}

.link {
  --color: black;
  --hover-color: rgb(0, 0, 0, 0.6);
  padding: 0;
  text-transform: initial;
}
.inlineLink {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
  min-height: 0;
  min-width: 0;
  outline: none;
  padding: 0;
  text-decoration: underline;
  text-transform: inherit;

  &:focus-within,
  &:hover {
    --hover-color: var(--color-accent-primary);
    opacity: 0.8;
  }
}

.icon {
  min-width: auto;
}

.icon svg {
  height: 1em;
}
