.burger {
  --gap-between-line: 5px;
  --line-height: 4px;
  cursor: pointer;
  height: calc(var(--gap-between-line) * 2 + var(--line-height) * 3);
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 32px;
  span {
    background: var(--color-primary-300);
    border-radius: 9px;
    display: block;
    height: var(--line-height);
    opacity: 1;
    position: absolute;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0;
      transform-origin: center;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(var(--line-height) + var(--gap-between-line));
    }

    &:nth-child(4) {
      top: calc((var(--line-height) + var(--gap-between-line)) * 2);
      transform-origin: center;
    }
  }

  &.open span {
    &:nth-child(4),
    &:nth-child(1) {
      opacity: 0;
      right: 50%;
      width: 0;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}
