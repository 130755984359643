/* stylelint-disable selector-class-pattern */

@custom-media --phone (max-width: 47.999rem);
@custom-media --tablet (min-width: 48rem);
@custom-media --tablet- (max-width: 62.999rem);
@custom-media --tablet-only (min-width: 48rem) and (max-width: 62.999rem);
@custom-media --desktop (min-width: 63rem);
@custom-media --desktop- (max-width: 83.999rem);
@custom-media --desktop-only (min-width: 63rem) and (max-width: 83.999rem);
@custom-media --desktop-large (min-width: 84rem);

@custom-media --mouse (pointer: fine);
@custom-media --touch (pointer: coarse);
@custom-media --landscape (orientation: landscape);
@custom-media --portrait (orientation: portrait);

@custom-media --device-pixel-ratio-2 (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx);

@keyframes _pulse {
  0%,
  100% {
    opacity: var(--pulseStart, 1);
  }
  50% {
    opacity: 0.5;
  }
}

:root {
  --line-bg: repeating-linear-gradient(
    -45deg,
    var(--line-first-bg-color, var(--color-primary-75)),
    var(--line-first-bg-color, var(--color-primary-75)) var(--bg-line-size, 2px),
    var(--line-second-bg-color, transparent) var(--bg-line-size, 2px),
    var(--line-second-bg-color, transparent) calc(var(--bg-line-size, 2px) * 3)
  );
  --line-height: 1.5;
  --line-height-heading: 0.95;
  --letter-spacing-upper: 0.03em;
  --letter-spacing-h1: -0.02em;
  /* ------------------------- FONT SIZE --------------------------- */
  --text-xxs: rem(12px);
  --text-xs: rem(14px);
  --text-s: rem(16px);
  --text-m: rem(20px);
  --text-l: rem(24px);
  --text-2l: rem(26px);
  --text-xl: rem(30px);
  --text-2xl: rem(34px);
  --text-3xl: rem(36px);
  --text-4xl: rem(38px);
  --text-5xl: rem(42px);
  --text-6xl: rem(51px);
  /* ------------------------- FONT WIDTH --------------------------- */
  --thin: 100;
  /* --extra-light: 200; */
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --semi-bold: 700;
  /*--black: 800*;*/
  --extra-black: 900;
  /* --------------------------- GAPS ------------------------------ */

  --x-gap: 1.56;

  --gap-tiny: 0.375rem;
  --gap-small: 0.75rem;
  --gap: 1.5rem;
  --gap-large: calc(var(--x-gap) * var(--gap));
  --gap-huge: calc(var(--x-gap) * var(--gap-large));

  --gap-button: 20px;
  --gap-title: 29px;
  --gap-vertical-section: 100px;
  --gap-horizontal-section: 0px;

  @media (--tablet-) {
    --gap-button: 15px;
    --gap-title: 20px;
    --gap-vertical-section: 61px;
    --gap-horizontal-section: 50px;
  }
  @media (--phone) {
    --gap-title: 29px;
    --gap-button: 10px;
    --gap-vertical-section: 48px;
    --gap-horizontal-section: 20px;
  }
  /* --------------------------- LAYOUT ------------------------------ */
  @media (--desktop) {
    --width-container: min(1160px, 100vw - 2 * var(--gap));
  }
  @media (--tablet-) {
    --width-container: 100vw;
  }

  --width-container-offcet: calc((100vw - var(--width-container)) / 2);

  --field-min-height: rem(55px);

  --hero-margin: calc(var(--width-container) * 0.5 - 50vw);
  --hero-padding: max(calc(50vw - var(--width-container) * 0.5), var(--gap-horizontal-section));

  --transition-duration: 0.3s;
  --duration-fast: 0.15s;
  --pulse-animation: _pulse 1.2s ease infinite;
  --container-border-radius: 4px;

  --hero-shadow: 0 0 2rem 0 #ccc;
  --background-secondary: #eee;

  --z-index-header: 10;
  --z-index-modal: 20;
  --z-index-dropdown: 9;

  --image-aspect-ratio: 16 / 9;
  /* --------------------------- SHADOW -------------------------------- */
  --market-accent-box-shadow: 0 2px 7px 2px #7b7b7b;
  /* --------------------------- COLOR -------------------------------- */
  --color-primary-500: #527079;
  --color-primary-400: #5f808b;
  --color-primary-300: #87b7c6;
  --color-primary-200: #9bc3d0;
  --color-primary-100: #b9d5de;
  --color-primary-75: #cee1e8;
  --color-primary-50: #f3f8f9;

  --color-secondary-500: #9c9368;
  --color-secondary-400: #b3a977;
  --color-secondary-300: #fff1aa;
  --color-secondary-200: #fff3b8;
  --color-secondary-200-50: color-mod(#fff3b8 alpha(0.5));
  --color-secondary-100: #fff7ce;
  --color-secondary-75: #fff9dc;
  --color-secondary-50: #fffef7;

  --color-neutral-900: #313026;
  --color-neutral-800: #3b3a31;
  --color-neutral-700: #48473e;
  --color-neutral-600: #56554d;
  --color-neutral-500: #606058;
  --color-neutral-400: #6d6c65;
  --color-neutral-300: #777670;
  --color-neutral-200: #83837d;
  --color-neutral-100: #908f8a;
  --color-neutral-90: #9c9c97;
  --color-neutral-80: #a8a8a4;
  --color-neutral-70: #b5b4b1;
  --color-neutral-50: #cccbc9;
  --color-neutral-40: #e4e4e3;
  --color-neutral-30: #efeeee;
  --color-neutral-20: #f7f7f6;
  --color-neutral-10: #fbfbfb;
  --color-neutral-0: #ffffff;
  --color-neutral-0-50: color-mod(#ffffff alpha(0.5));

  --color-tertiary-400: #86664d;
  --color-tertiary-300: #bf916e;
  --color-tertiary-200: #caa487;
  --color-tertiary-100: #dabfab;
  --color-tertiary-75: #e5d2c4;
  --color-tertiary-50: #f9f4f1;

  --color-quaternary-500: #63663f;
  --color-quaternary-400: #717648;
  --color-quaternary-300: #a2a867;
  --color-quaternary-200: #b2b781;
  --color-quaternary-100: #c9cda7;
  --color-quaternary-75: #d9dbc1;
  --color-quaternary-50: #f6f6f0;

  --color-accent-200: #e17900;
  --color-accent-100: #ff3368;
  --color-accent-0: #e9d9e4;

  --color-success: #7fb655;
  --color-success-transparent: color-mod(var(--color-success) alpha(0.6));

  --color-heading: #333;
  --color-accent-secondary: var(--color-accent-0);
  --color-accent-primary: var(--color-accent-100);
  --color-danger: #e5674c;
  --color-error: #bd0000;
  --color-error-light: #fff9f9;
  --color-text-secondary: var(--color-neutral-90);
  --color-text-primary: var(--color-neutral-500);
  --background-color-primary: #ffff;
  --backdrop-color: color-mod(var(--color-primary-500) alpha(0.7));
  --main-article-color: var(--color-tertiary-300);
  --main-border-color: var(--color-neutral-50);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 100%;
  height: 100%;
}

html,
body {
  color: var(--color-text-primary);
  font-family: var(--font-family), system-ui;
  font-weight: 400;
  line-height: var(--line-height);
  margin: 0;
  padding: 0;
  scroll-padding: rem(150px);

  @media (--tablet-) {
    scroll-padding: rem(160px);
  }

  @media (--phone) {
    scroll-padding: rem(90px);
  }
}

body,
#__next {
  height: inherit;
}

body {
  overflow-x: hidden;
}

a {
  color: inherit;
}

p,
ul,
ol {
  font-size: var(--text-s);
  margin-block-end: 1em;
  margin-block-start: 0;
}

h1,
h2,
h3 {
  font-family: var(--font-family-heading);
}

.content {
  margin: 0 auto;
  max-width: 65.5rem;
  padding: var(--gap);
  width: 100%;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.nowrap {
  white-space: nowrap;
}

.swiper-button-prev,
.swiper-button-next {
  /* stylelint-disable-next-line declaration-no-important */
  height: 40px !important;
  /* stylelint-disable-next-line declaration-no-important */
  width: 40px !important;

  &::after {
    background-image: url('../icons/arrow-right.svg');
    background-position: right;
    background-repeat: no-repeat;
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 0 !important;
    height: 24px;
    width: 14px;
  }
}

.swiper-button-next::after {
  position: absolute;
}

.swiper-button-prev::after {
  transform: rotate(180deg);
}

img {
  will-change: transform; /*for fix blur image whe use tranform*/
}

input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
