.header {
  --horizontal-padding: var(--gap-large);

  background-color: var(--background-color-primary);
  box-shadow: 0 24px 34px -27px rgba(0, 0, 0, 0.07);
  overflow: visible;
  padding-bottom: var(--horizontal-padding);
  padding-top: var(--horizontal-padding);
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);

  @media (--tablet-) {
    --horizontal-padding: var(--gap);
  }
}
