.section {
  --circle-bg: var(--color-secondary-200-50);
  --circle-size: 50rem;
  --circle-top: 75%;
  --circle-left: 50%;

  --dots-top: 85%;
  --dots-bottom: 0;
  --dots-right: 0;
  --dots-left: 70%;

  display: block;
  margin: 0 auto;
  max-width: var(--width-container);
  padding: var(--gap-vertical-section) var(--gap-horizontal-section);
  position: relative;
  /** z-index: 1; interferes with fixed positioned elements like react-hook-form dev tools, do we actually need this? */
  width: 100%;

  @media (--tablet-) {
    --vertical-padding: rem(50px);
    overflow-x: hidden;
  }

  @media (--phone) {
    --vertical-padding: rem(20px);
    text-align: center;
  }

  &.circle {
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:before {
      background: var(--circle-bg);
      border-radius: 50%;
      content: '';
      display: block;
      height: var(--circle-size);
      left: var(--circle-left);
      position: absolute;
      top: var(--circle-top);
      width: var(--circle-size);
      z-index: -1;
    }
  }
  &.dots {
    overflow: hidden;
    position: relative;
    z-index: 1;
    &:after {
      background-image: url(~/icons/dot-bg-tertiary-75.svg);
      bottom: var(--dots-bottom);
      content: '';
      display: block;
      left: var(--dots-left);
      position: absolute;
      right: var(--dots-right);
      top: var(--dots-top);
      z-index: -1;
    }
  }
}

.stepContainer,
.hero {
  box-sizing: content-box;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  position: relative;
  width: auto;
}

.hero {
  padding: var(--gap-huge) var(--hero-padding);
}

.stepContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: var(--gap-huge) var(--hero-padding);

  @media (--tablet-) {
    padding: var(--gap-large) var(--hero-padding);
  }

  @media (--phone) {
    padding: var(--gap);
  }
}

.outerGap {
  margin: var(--gap-vertical-section) auto;
  padding: 0 var(--gap-horizontal-section);
}

.withoutGap {
  padding: 0 var(--gap-horizontal-section);
}

.outerGapSecondSection {
  margin-bottom: var(--gap-horizontal-section);
  margin-top: var(--gap-huge);
  padding-top: 0;
}

.innerGapSecondSection {
  padding-bottom: var(--gap-horizontal-section);
  padding-top: var(--gap-huge);
}
