.orderStatus {
  align-items: center;
  display: flex;

  &::before {
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: rem(12px);
    margin-right: var(--gap-small);
    width: rem(12px);
  }

  &.issuedActive::before,
  &.payoutCompleted::before {
    background-color: #68c87d;
  }

  &.purchaseInitiated::before,
  &.paymentInitiated::before,
  &.payoutOwed::before {
    background-color: #ffc164;
  }

  &.issuedPending::before {
    background-color: var(--color-primary-300);
  }

  &.suspended::before,
  &.decline::before,
  &.notIssued::before,
  &.cancelled::before,
  &.cancelledByClient::before,
  &.expired::before {
    background-color: #ff8064;
  }
}
