@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.buttonLoading {
  position: relative;

  &[data-loading='true'] {
    cursor: wait;
    &::after {
      animation: button-loading-spinner 0.8s ease infinite;
      border: 2px solid transparent;
      border-radius: 50%;
      border-top-color: currentColor;
      bottom: 0;
      content: '';
      height: 0.8em;
      left: 0;
      margin: auto;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 0.8em;
    }
  }
}

.content {
  align-items: inherit;
  display: inherit;
  justify-content: inherit;
  text-align: inherit;

  &[data-loading='true'] {
    opacity: 0;

    &.partialHideMode {
      opacity: 0.4;
    }
  }
}
