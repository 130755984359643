.pagination {
  --pagination-color: var(--main-article-color);

  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: var(--gap-huge);

  ol {
    display: inline-flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
