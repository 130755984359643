.actions {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: auto auto;
  justify-content: flex-start;
  margin-top: var(--gap-large);

  @media (--phone) {
    grid-gap: 0;
    grid-template-columns: auto;
    justify-content: normal;
  }
}
