.grid {
  position: relative;

  &.wait {
    animation: var(--pulse-animation);
    cursor: wait;
  }
}

.gridRow {
  --action-column-width: 40px;
  --border: 1px solid var(--color-neutral-30);

  border-bottom: var(--border);
  display: grid;
  grid-template-columns:
    repeat(
      var(--main-column-number),
      calc((100% - (var(--action-column-width))) / var(--main-column-number))
    )
    var(--action-column-width);
  text-align: left;

  &:focus-within,
  &:hover {
    &:not(.headerRow) {
      background-color: var(--color-primary-50);
    }
  }

  @media (--phone) {
    border: var(--border);
    border-radius: var(--container-border-radius);
    display: grid;
    grid-template-areas: 'createdAt' 'contractNumber' 'contractHolderName' 'status' 'maxPayout' 'actions';
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: var(--gap-small);
    padding: 24px;
  }
}

.headerRow {
  position: relative;
  @media (--phone) {
    display: none;
  }
}

.gridLoading {
  left: 50%;
  position: absolute;
  top: 60px;
  transform: translateX(-50%);
}

.gridItem {
  padding: 20px 8px;
  width: 100%;
  word-break: break-word;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (--tablet) {
    display: block;
  }

  @media (--phone) {
    padding: 0;
  }
}

.actions {
  @media (--phone) {
    align-self: start;
    grid-area: actions;
    justify-content: end;
    opacity: 1;
  }
}

.headerTitle {
  font-weight: 600;
}

.noData {
  color: var(--color-neutral-70);
  display: block;
  font-weight: 500;
  padding: var(--gap) 0;
  text-align: center;
}

@media (--phone) {
  .createdAt {
    color: var(--color-neutral-100);
    grid-area: createdAt;
  }

  .contractNumber {
    grid-area: contractNumber;
  }

  .contractHolderName {
    color: var(--color-neutral-900);
    display: block;
    font-size: var(--text-m);
    font-weight: 500;
    grid-area: contractHolderName;
    line-height: 1.1em;
    margin-bottom: var(--gap-tiny);
  }

  .status {
    align-self: end;
    grid-area: status;
  }

  .maxPayout {
    grid-area: maxPayout;
  }
}

.pagination {
  --pagination-color: var(--color-primary-400);
  margin-top: 0;

  @media (--phone) {
    margin-bottom: var(--gap);
    order: -1;
    width: 100%;
  }
}

.record {
  @media (--phone) {
    display: none;
  }
}

.rowCount {
  color: var(--color-neutral-600);
  font-size: var(--text-xs);
  text-align: right;

  @media (--phone) {
    display: none;
  }
}

.footer {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (--phone) {
    grid-template-columns: 1fr;
  }
}
