.form {
  display: grid;
  grid-gap: var(--gap-small);
  margin-top: var(--gap-huge);
  @media (--desktop) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.agree {
  --checkbox-mark-color: var(--color-primary-300);
  margin-top: var(--gap);

  @media (--phone) {
    margin-top: var(--gap-small);
  }
}

.agreeLabel {
  align-items: flex-start;
}

@media (--desktop) {
  .email {
    grid-column: 1/3;
  }

  .fName {
    grid-column: 1/2;
  }

  .lName {
    grid-column: 2/3;
  }

  .agree {
    grid-column: 1/3;
  }
}

.submit {
  justify-self: start;
  margin-top: 0;
  min-width: 207px;

  @media (--tablet-) {
    justify-self: center;
  }
}
