.ldsEllipsis {
  --loader-dot-size: 4px;
  --loader-between-gap: calc(var(--loader-dot-size) * 0.75);
  --loader-color: var(--color-primary-300);
  box-sizing: content-box;
  cursor: wait;

  display: inline-block;
  height: var(--loader-dot-size);
  position: relative;
  width: calc(var(--loader-dot-size) * 3 + var(--loader-between-gap) * 2);

  span {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: var(--loader-color);
    border-radius: 50%;
    height: var(--loader-dot-size);
    position: absolute;
    width: var(--loader-dot-size);

    &:nth-child(1) {
      animation: lds-ellipsis1 0.6s infinite;
      left: 0;
    }

    &:nth-child(2) {
      animation: lds-ellipsis2 0.6s infinite;
      left: 0;
    }

    &:nth-child(3) {
      animation: lds-ellipsis2 0.6s infinite;
      left: calc(var(--loader-between-gap) + var(--loader-dot-size));
    }

    &:nth-child(4) {
      animation: lds-ellipsis3 0.6s infinite;
      left: calc((var(--loader-between-gap) + var(--loader-dot-size)) * 2);
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(var(--loader-between-gap) + var(--loader-dot-size)), 0);
  }
}

.mediumSize {
  --loader-dot-size: 12px;
}

.largeSize {
  --loader-dot-size: 20px;
}

.accentTheme {
  --loader-color: var(--color-accent-primary);
}

.smallSize {
  --loader-dot-size: 6px;
}

.inheritTheme {
  --loader-color: currentColor;
}
