.error {
  margin: var(--gap) 0;
}

.errorMessage {
  display: block;
  font-size: var(--text-m);
  margin-bottom: var(--gap-small);

  &:first-letter {
    text-transform: uppercase;
  }
}
