.heading {
  text-align: center;
}

.btn {
  min-width: 190px;
  transition: all 0.3s;

  &:hover {
    background-color: var(--color-primary-400);
    color: var(--color-neutral-0);
  }
}
