.select {
  border: 1px solid var(--color-neutral-30);
  border-radius: var(--container-border-radius);
  font-size: inherit;
  outline: none;
  padding: 5px 14px;
  position: relative;

  &:hover,
  &:focus-visible {
    border-color: var(--color-primary-400);
  }

  &::after {
    background-color: red;
    content: '';
    height: 10px;
    position: absolute;
    width: 10px;
  }
}

.container {
  align-items: center;
  display: inline-flex;
}

.text {
  color: var(--color-neutral-600);
  font-size: var(--text-xs);
  font-weight: 600;
  margin-left: 16px;
}
