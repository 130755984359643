.container {
  --user-info-border: 1px solid var(--color-neutral-50);
  --user-info-border-radius: var(--container-border-radius);
  --user-info-horisonal-padding: 16px;
  grid-area: authUser;
  min-width: 190px;

  @media (--tablet) {
    max-width: 400px;
  }

  @media (--phone) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.amountContainer {
  display: flex;
  justify-content: flex-end;

  @media (--phone) {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    text-align: right;
  }
}

.text {
  color: var(--color-neutral-200);
  display: flex;
  font-size: var(--text-xxs);
  max-width: rem(100px);
  text-align: right;

  &::after {
    align-self: stretch;
    background-color: var(--color-neutral-40);
    content: '';
    display: block;
    flex-shrink: 0;
    height: 100%;
    margin: 0 var(--gap-small);
    width: 1px;
  }

  @media (--phone) {
    max-width: none;
  }
}

.amount {
  color: var(--color-neutral-900);
  flex-shrink: 0;
  font-size: rem(22px);
  font-weight: 500;
  text-align: right;

  @media (--phone) {
    flex-grow: 0;
  }
}

.menuContainer {
  position: relative;

  @media (--phone) {
    margin-bottom: var(--gap-small);
    margin-top: var(--gap);
  }
}

.menuButton {
  align-items: center;
  background-color: var(--color-neutral-10);
  border: var(--user-info-border);
  border-radius: var(--user-info-border-radius);
  color: var(--color-primary-400);
  cursor: pointer;
  display: flex;
  font-size: var(--text-xxs);
  margin-left: auto;
  min-height: 44px;
  padding: 8px var(--user-info-horisonal-padding);
  white-space: nowrap;
  width: 100%;

  @media (--phone) {
    width: 100%;
  }
}

.showNav {
  background-color: var(--background-color-primary);
  border-bottom-color: transparent;
  border-radius: var(--user-info-border-radius) var(--user-info-border-radius) 0 0;

  .arrow {
    transform: rotate(180deg);
  }
}

.fullName {
  flex-grow: 1;
  margin: 0;
  margin-right: var(--gap-small);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.avatar {
  background-color: var(--color-primary-300);
  border: none;
  flex-shrink: 0;
  margin-right: var(--gap-small);
}

.arrow {
  flex-shrink: 0;
  height: 1em;
  object-fit: contain;
  transform: rotate(0);
  transition: var(--transition-duration) transform ease-in-out;
  width: 1em;
}
