.list {
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem:not(:last-of-type) {
  margin-bottom: var(--gap-small);
}

.listItemAccent {
  font-size: var(--text-l);
  font-weight: 500;

  &:not(:last-of-type) {
    margin-bottom: var(--gap-small);
  }
}

.link {
  text-decoration: none;

  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }
}
