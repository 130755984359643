.heading {
  margin-bottom: var(--gap-large);
  text-align: center;
}

.button {
  min-width: 11em;
  transition: all 0.3s;

  &:hover {
    background-color: var(--color-primary-400);
    color: var(--color-neutral-0);
  }
}

.tooltipBox {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
