.mark {
  --mark-opacity: 0;
  --checkbox-background: #fff;
  align-items: center;
  appearance: none;
  background-color: var(--checkbox-background, transparent);
  border: 1px solid var(--checkbox-border-color, var(--color-primary-300));
  border-radius: var(--container-border-radius);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: var(--text-xs);
  font-weight: var(--light);
  height: 1.4rem;
  justify-content: center;
  left: 0;
  margin: 0;
  margin-right: var(--gap-small);
  top: 0;
  width: 1.4rem;

  svg {
    color: var(--checkbox-mark-color, var(--color-primary-500));
    height: 70%;
    opacity: var(--mark-opacity);
    width: 70%;
  }
}

.label {
  align-items: flex-start;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--text-xs);
  font-weight: 400;
  user-select: none;
}

.error {
  margin-top: var(--gap-tiny);
}

.input {
  &:focus-visible + .mark {
    --mark-opacity: 0.5;
  }
  &:checked + .mark {
    --mark-opacity: 1;
  }
}

.accentMark {
  --checkbox-mark-color: var(--color-accent-primary);
}

.startAlign {
  align-items: flex-start;

  & .mark {
    margin-top: 0.2em;
  }
}
