.footer {
  display: grid;
}

.logo {
  height: 25px;
  width: 192px;

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.logoContainer {
  height: 25px;
  margin-bottom: var(--gap);
  width: 192px;

  @media (--tablet-) {
    margin: 0 auto var(--gap) auto;
  }
}

.container {
  background-color: var(--color-primary-500);
  color: var(--color-neutral-0);
}

.section {
  display: grid;
  grid-gap: var(--gap-huge);
  grid-template-columns: 1fr 1fr;
  padding: var(--gap-vertical-section) var(--gap-horizontal-section);

  @media (--tablet-only) {
    grid-gap: 150px;
    grid-template-columns: 1fr;
    padding-left: 150px;
    padding-right: 150px;
    text-align: center;
  }

  @media (--phone) {
    grid-gap: 105px;
    grid-template-columns: 1fr;
  }
}

.nav {
  align-items: start;
  display: grid;
  grid-gap: var(--gap-large);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: var(--gap-huge);
  text-align: left;

  @media (--phone) {
    grid-gap: var(--gap-huge);
    text-align: center;
  }
}

.partnerLogin {
  display: inline-block;
  position: relative;

  &::before {
    background-image: url('../../icons/partnerIcon.svg');
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: rem(20px);
    position: absolute;
    right: calc(-0.8 * var(--gap-large));
    top: 50%;
    transform: translateY(-50%);
    width: rem(16px);
  }
}

.socialMediaWrapper {
  height: 40px;

  @media (--tablet-) {
    margin-top: var(--gap-vertical-section);
  }
}

.navLink {
  display: inline-block;
  height: 40px;
  margin-right: var(--gap-tiny);
  position: relative;
  width: 40px;
}

.leftFootersection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.desktopView {
  display: block;

  @media (--tablet-) {
    display: none;
  }
}

.tabletView {
  display: none;

  @media (--tablet-) {
    display: block;
  }
}
