.message {
  color: var(--color-error);
  font-size: var(--text-xs);
  font-weight: 500;
  text-shadow: -1px 0 var(--background-color-primary), 0 1px var(--background-color-primary),
    1px 0 var(--background-color-primary), 0 -1px var(--background-color-primary);
  z-index: 0;

  &:first-letter {
    text-transform: uppercase;
  }
}
