.header {
  --header-height: auto;
  align-content: center;
  align-items: center;
  display: grid;
  grid-template-areas: 'logo title back';
  grid-template-columns: auto 1fr auto;
  height: auto;
  text-align: left;

  @media (--tablet-) {
    grid-template-areas: 'logo back' 'title back';
    grid-template-columns: 1fr auto;
    position: static;
  }

  @media (--phone) {
    align-content: center;
    grid-template-areas: 'title logo';
  }
}

.sectionAuth {
  align-items: center;
  column-gap: var(--gap);
  grid-template-areas: 'logo back authUser' 'title back authUser';
  grid-template-columns: 1fr auto;

  @media (--phone) {
    grid-template-areas: 'title logo back' 'authUser authUser back';
  }
}

.logoContainer {
  height: 24px;
  margin-bottom: 0;
  width: 182px;
  @media (--tablet-) {
    height: 21px;
    width: 159px;
  }

  @media (--phone) {
    height: 14px;
    width: 108px;
  }
}

.logo {
  &:focus-visible {
    border: none;
    outline: none;
  }
}

.logoWrapper {
  align-items: center;
  display: flex;
  grid-area: logo;
  justify-self: start;
  margin-right: var(--gap-small);
  padding: 3px;

  @media (--tablet-) {
    padding: 2px;
  }

  @media (--phone) {
    padding: 0;
  }
}

.title {
  text-decoration: none;

  h2 {
    color: var(--color-neutral-900);
    display: inline-block;
    font-family: var(--font-family);
    font-size: var(--text-3xl);
    font-weight: 500;
    grid-area: title;
    line-height: 1em;
    margin: 0;
    text-transform: capitalize;

    strong {
      display: inline-block;
    }

    @media (--tablet-) {
      font-size: 28px;
    }
    @media (--phone) {
      display: flex;
      flex-direction: column;
      font-size: 20px;

      & strong {
        margin-top: var(--gap-tiny);
      }
    }
  }
}

.backLink {
  grid-area: back;
  padding-bottom: 0;
  padding-top: 0;
  text-align: left;

  @media (--desktop) {
    & > br {
      display: none;
    }
  }

  @media (--phone) {
    display: none;
  }
}

.startOverButton {
  grid-area: startOverButton;
}
