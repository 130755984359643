.heading {
  --desktop-size: var(--text-2xl);
  font-family: var(--font-family-heading);
  font-size: var(--desktop-size);
  line-height: var(--line-height-heading);
  margin: 0;
  padding: 0;

  @media (--tablet-) {
    font-size: var(--tablet-size, var(--desktop-size));
  }

  @media (--phone) {
    font-size: var(--phone-size, var(--desktop-size));
  }

  &:first-letter {
    text-transform: uppercase;
  }
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.h1Theme {
  --desktop-size: var(--text-6xl);
  --tablet-size: var(--text-5xl);
  --phone-size: var(--text-4xl);

  font-weight: var(--bold);
  margin-bottom: var(--gap-title);
  @media (--desktop) {
    letter-spacing: var(--letter-spacing-h1);
  }
}

.h2Theme {
  --desktop-size: var(--text-5xl);
  --tablet-size: var(--text-3xl);
  --phone-size: var(--text-2xl);
  color: var(--color-primary-500);

  font-weight: var(--bold);
  margin-bottom: var(--gap);
}

.h3Theme {
  --desktop-size: var(--text-xl);

  font-weight: var(--light);
  margin-bottom: var(--gap);
}

.h4Theme {
  --desktop-size: var(--text-l);
  font-family: var(--font-family);
}

.h5Theme {
  --desktop-size: var(--text-m);
  font-family: var(--font-family);
  font-weight: var(--medium);
  line-height: 1.3;
}

.h6Theme {
  --desktop-size: var(--text-xs);
  font-family: var(--font-family);
  font-weight: var(--bold);
}
