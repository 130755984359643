.styledLink {
  --main-link-color: var(--color-primary-300);
  --action-link-color: var(--color-primary-300);

  color: var(--main-link-color);
  &:focus-visible,
  &:hover {
    color: var(--action-link-color);
  }
}

.primaryLight {
  --main-link-color: var(--color-primary-300);
  --action-link-color: color-mod(var(--color-primary-300) shade(20%));
}

.primary {
  --main-link-color: var(--color-primary-400);
  --action-link-color: color-mod(var(--color-primary-400) shade(20%));
}
