.nav {
  @media (--tablet-) {
    width: 100%;
  }

  @media (--tablet-only) {
    margin-top: var(--tablet-nav-margin-top);
  }

  @media (--phone) {
    background-color: var(--background-color-primary);
    box-shadow: inset 0 24px 34px -27px rgba(0, 0, 0, 0.07);
    display: block;
    left: 0;
    max-height: calc(100vh - var(--layout-header-height));
    overflow-y: auto;
    padding: var(--gap-large);
    position: absolute;
    top: 99%;
    transform: translateY(-100%);
    width: 100%;
    z-index: -1;

    &.open {
      transform: translateY(0);
    }
  }

  &.withAnimation {
    transition: var(--transition-duration) ease-in-out;
  }
}

.list {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (--tablet-) {
    justify-content: space-between;
    width: 100%;
  }

  @media (--phone) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.formattedList {
  @media (--tablet-) {
    justify-content: space-evenly;
  }
}

.item {
  color: var(--color-neutral-400);
  line-height: 1.4em;
  margin-left: var(--gap-large); /*fallback*/
  margin-left: min(var(--gap-large), 3vw);
  padding: 0;

  &:first-child {
    margin-left: 0;
  }

  @media (--tablet-) {
    margin-left: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (--desktop) {
    &:last-child {
      margin-left: 0;
    }
  }

  @media (--phone) {
    --header-link-gap: var(--gap-small) 0;
    margin: 0;
    width: 100%;

    &:nth-last-child(n + 3) {
      border-bottom: 1px solid var(--color-neutral-30);
    }

    &:first-of-type {
      --header-link-gap: 0 0 var(--gap-small) 0;
    }
  }
}

.buyNowMobileItem {
  @media (--tablet) {
    display: none;
  }

  @media (--phone) {
    width: 100%;

    .buyNowMobileLink {
      margin-top: var(--gap-small);
      width: 100%;
    }
  }
}

.link {
  font-weight: 700;
  letter-spacing: 0.03em;
  outline: none;
  text-decoration: none;
  text-overflow: clip;
  transition: background-color var(--transition-duration) ease;
  white-space: nowrap;

  &.active,
  &:focus-visible,
  &:hover {
    color: var(--color-primary-300);
  }

  &.active {
    cursor: default;
  }

  @media (--phone) {
    display: block;
    padding: var(--header-link-gap);
  }
}

.mobileMenuOverlay {
  display: none;
  pointer-events: none;
  user-select: none;

  @media (--phone) {
    background-color: var(--backdrop-color);
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: var(--transition-duration) ease-in-out;
    width: 100%;
    z-index: -1;

    &.openOverlay {
      opacity: 1;
      pointer-events: initial;
    }
  }
}
