.container {
  background-color: var(--color-neutral-900);
  color: var(--color-neutral-0);
  font-size: rem(13px);
  padding-bottom: var(--gap);
  padding-top: var(--gap);
}

.section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.copyPast {
  @media (--phone) {
    margin-bottom: var(--gap);
  }
  flex-grow: 1;
}

.nav {
  @media (--phone) {
    width: 100%;
  }
}

.list {
  align-items: center;

  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  margin-left: calc(var(--gap) * -1);
  padding: 0;

  @media (--phone) {
    flex-wrap: wrap;
  }
}

.listItem {
  margin-left: var(--gap);
}

.listItemLink {
  text-decoration: none;

  &:focus-visible,
  &:hover {
    text-decoration: underline;
  }
}
