.item {
  --item-size: 34px;
  --item-gap: 8px;

  &:not(:first-child) {
    margin-left: var(--item-gap);
  }

  @media (--phone) {
    --item-gap: 4px;
  }
}

.link {
  align-items: center;
  border: 1px solid var(--pagination-color, var(--main-article-color));
  border-radius: var(--container-border-radius);
  color: var(--pagination-color, var(--main-article-color));
  display: inline-flex;
  height: var(--item-size);
  justify-content: center;
  min-width: var(--item-size);
  outline: none;
  padding: var(--gap-tiny);
  text-align: center;
  text-decoration: none;

  &.activeClassName,
  &:not(.disabled):focus-visible,
  &:not(.disabled):hover {
    background-color: var(--pagination-color);
    color: var(--color-neutral-0);
  }
}

.dots {
  border-color: transparent;
  pointer-events: none;
  touch-action: none;

  @media (--phone) {
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.arrowItem {
  align-items: center;
  display: flex;
  justify-content: center;
}

.arrow {
  height: 20px;
  margin: auto;
  width: 9px;
}

.disabled {
  border: 1px solid currentColor;
  color: var(--color-neutral-50);
  cursor: not-allowed;
}

.nextArrow {
  transform: rotate(180deg);
}
