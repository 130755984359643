.container {
  align-items: center;
  color: var(--color-primary-300);

  display: flex;
  flex-direction: column;
  margin: var(--gap);
}

.loader {
  color: inherit;
  margin-bottom: var(--gap-small);
}

.title {
  color: inherit;
}

.desc {
  color: var(--color-neutral-90);
  font-size: var(--text-xs);
  margin-bottom: 0;
  margin-top: var(--gap-tiny);
}

.accentTheme {
  color: var(--color-accent-primary);
}
