.searchInputContainer {
  position: relative;
}

.searchIcon {
  color: var(--color-neutral-100);
  content: '';
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 50px;
  top: 50%;
  touch-action: none;
  transform: translateY(-50%);
  user-select: none;
  width: 20px;
}

.searchInput {
  padding-right: 75px;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.clearIcon {
  background-color: var(--color-neutral-0);
  color: var(--color-neutral-100);
  content: '';
  cursor: pointer;
  height: 25px;
  opacity: 0.7;
  outline: none;
  padding: 5px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  width: 25px;

  &:hover {
    opacity: 1;
  }
}
