.addon {
  align-items: center;
  background-color: var(--color-primary-50);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  bottom: var(--border-width);
  color: var(--color-primary-400);
  display: flex;
  font-size: var(--text-m);
  font-weight: var(--medium);
  justify-content: center;
  left: var(--border-width);
  pointer-events: none;
  position: absolute;
  top: var(--border-width);
  width: var(--addon-width);
  &:after {
    content: '$';
  }
}

.wrapper {
  --addon-width: 2.8125rem;
  position: relative;

  input {
    padding-left: calc(var(--padding-inline) + var(--addon-width));
    &::-webkit-inner-spin-button {
      margin: 0 var(--gap-tiny);
    }
  }
}
