.select {
  appearance: none;
  padding-right: rem(40px);
  width: 100%;
}

.select:disabled {
  opacity: 1; /* override native style */
}

.option {
  background-color: var(--color-primary-50);
  color: var(--color-text-primary);
  &:disabled {
    color: red;
  }
}

.placeholder {
  color: var(--color-neutral-70);
}

.container {
  position: relative;

  &::after {
    background-image: url('./icon.svg');
    background-repeat: no-repeat;

    background-size: contain;
    bottom: 50%;
    content: '';
    height: 8px;
    pointer-events: none;
    position: absolute;
    right: var(--gap);
    transform: translateY(50%);
    width: 14px;
    z-index: 1;
  }
}
