.container {
  text-align: left;
}

.ruleList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: var(--gap);
  text-align: left;

  @media (--tablet-) {
    padding-left: 0;
  }
}

.rule {
  align-items: center;
  color: var(--color-neutral-300);
  display: flex;
  font-size: var(--text-xs);
  margin-bottom: var(--gap-tiny);
  position: relative;
  text-transform: lowercase;
  white-space: nowrap;
  width: 50%;

  &.done {
    .icon {
      background: var(--color-success);
      border-color: transparent;
      color: var(--color-neutral-0);
      opacity: 0.6;
    }
  }
}

.icon {
  border: 1px solid var(--color-primary-300);
  border-radius: 50%;
  color: transparent;
  flex-shrink: 0;
  height: rem(15px);
  margin-right: 8px;
  width: rem(15px);
}

.ruleTitle {
  color: var(--color-neutral-900);
  display: block;
  font-size: var(--text-xxs);
  margin-bottom: var(--gap-small);
}

.field {
  margin-bottom: 44px;

  @media (--tablet-) {
    margin-bottom: var(--gap);
  }
}

.capitalLetter {
  order: 1;
}

.lowercaseLetter {
  order: 3;
}

.number {
  order: 5;
}

.specialCharacter {
  order: 2;
}

.lengthLimit {
  order: 4;
}
