.labelWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.label {
  --label-text-transform: initial;
  color: var(--color-neutral-500);
  font-size: var(--text-m);
  font-weight: 500;
  margin-right: 20px;
}

.description {
  color: var(--color-neutral-90);
  display: inline-block;
  margin-bottom: 17px;
}

.percentSlideTooltip {
  align-items: center;
  color: #d14532;
  display: grid;
  grid-gap: 6px;
  grid-template-columns: auto auto;
  justify-content: center;
}
