.fontSizeXxs {
  font-size: var(--text-xxs);
}

.fontSizeXs {
  font-size: var(--text-xs);
}

.fontSizeS {
  font-size: var(--text-s);
}

.fontSizeM {
  font-size: var(--text-m);

  @media (--phone) {
    font-size: var(--text-s);
  }
}

.fontSizeL {
  font-size: var(--text-l);

  @media (--phone) {
    font-size: var(--text-m);
  }
}

.fontSizeXl {
  font-size: var(--text-xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}

.fontSize2xl {
  font-size: var(--text-2xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}

.fontSize3xl {
  font-size: var(--text-3xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}

.fontSize4xl {
  font-size: var(--text-4xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}

.fontSize5xl {
  font-size: var(--text-5xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}

.fontSize6xl {
  font-size: var(--text-6xl);

  @media (--phone) {
    font-size: var(--text-l);
  }
}
