.mainContainer {
  --inner-horizontal-padding: var(--gap-huge);
  --inner-vertical-padding: 25px;
  bottom: 0;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-modal);

  @media (--tablet-) {
    --inner-horizontal-padding: 45px;
  }

  @media (--phone) {
    --inner-horizontal-padding: 22px;
    --inner-vertical-padding: var(--gap-small);
  }
}

.overlay {
  background-color: var(--backdrop-color);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.dialog {
  align-items: center;
  display: flex;
  margin: calc(var(--gap) / 2) auto;
  max-height: calc(100% - var(--gap));
  max-width: calc(100% - 40px);
  min-height: calc(100% - var(--gap));
  pointer-events: none;
  position: relative;
  width: 925px;

  @media (--phone) {
    max-width: calc(100% - 15px);
  }
}

.content {
  background-clip: padding-box;
  background-color: var(--background-color-primary);
  border-radius: var(--container-border-radius, 0.5rem);
  box-shadow: 0 14px 24px -10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  max-height: calc(100% - var(--gap));
  outline: 0;
  overflow: hidden;
  padding-top: 38px;
  pointer-events: auto;
  position: absolute;
  width: 100%;

  @media (--phone) {
    padding-top: var(--inner-horizontal-padding);
  }
}

@keyframes showDialog {
  100% {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

.animation {
  animation: showDialog var(--transition-duration) ease-in-out forwards;
  opacity: 0;
  transform: translateY(8%);
}

.headerContainer {
  align-items: center;
  border-bottom: 1px solid var(--color-neutral-30);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 var(--inner-horizontal-padding) var(--inner-vertical-padding)
    var(--inner-horizontal-padding);
}

.header {
  color: var(--color-primary-400);
  font-weight: 600;
  margin: 0;
  padding: 0;

  @media (--phone) {
    font-size: var(--text-s);
  }
}

.body {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  overflow-y: auto;
  padding: 36px var(--inner-horizontal-padding) 55px var(--inner-horizontal-padding);
  position: relative;

  @media (--phone) {
    padding-bottom: 36px;
  }
}

.closeIcon {
  border-radius: 0;
  color: var(--color-primary-400);
  font-size: var(--text-s);
  font-weight: 400;
  margin-left: auto;
  outline: none;
  position: relative;

  svg {
    height: 14px;
    width: 15px;
  }

  &:after {
    content: '';
    display: block;
    height: 36px;
    opacity: 0.4;
    position: absolute;
    width: 130%;
  }

  span {
    color: var(--color-neutral-90);

    @media (--phone) {
      display: none;
    }
  }

  &:focus-within,
  &:hover {
    svg,
    span {
      color: var(--color-accent-primary);
    }
  }
}
/*fullscreen-theme*/
.fullscreenThemeContent {
  height: 100%;
  max-height: none;
  max-width: none;
  padding: 0;
  width: 100%;
}

.fullscreenThemeDialog {
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: auto;
  min-width: auto;
  width: 100%;
}

.fullscreenThemeBody {
  padding: var(--gap-small);
}
/*fullscreen-theme*/
/* only content theme */
.onlyContentThemeContent {
  padding: 0;
}

.onlyContentThemeBody {
  padding: 102px;

  @media (--tablet-) {
    padding: 69px;
  }

  @media (--phone) {
    padding: 29px;
  }
}
/* only content theme */
